import { SubscriptionIntervalType } from "types/subscription";

export const APP_SIDE_MENU_WIDTH = 200;

export const ALL_TIMEZONE = [
  { name: "Africa/Lagos (GMT +01:00)", value: "Africa/Lagos" },
  { name: "(Pacific/Honolulu) GMT -10", value: "Pacific/Honolulu" },
  { name: "(America/Anchorage) GMT -9", value: "America/Anchorage" },
  { name: "(America/Los_Angeles) GMT -8", value: "America/Los_Angeles" },
  { name: "(America/Chicago) GMT -6", value: "America/Chicago" },
  { name: "America/New_York(GMT -05:00)", value: "America/New_York" },
  { name: "America/Toronto(GMT -04:00)", value: "America/Toronto" },
  { name: "(Europe/London) GMT +0", value: "Europe/London" },
  { name: "Africa/Accra (GMT +00:00)", value: "Africa/Accra" },
  { name: "Africa/Algiers (GMT +01:00)", value: "Africa/Algiers" },
  { name: "Africa/Harare (GMT +02:00)", value: "Africa/Harare" },
  { name: "Africa/Kigali (GMT +02:00)", value: "Africa/Kigali" },
  { name: "Africa/Nairobi (GMT +03:00)", value: "Africa/Nairobi" },
  { name: "Asia/Dubai (GMT +04:00)", value: "Asia/Dubai" },
  { name: "Asia/Oral (GMT +05:00)", value: "Asia/Oral" },
  { name: "Asia/Almaty (GMT +06:00)", value: "Asia/Almaty" },
  { name: "Asia/Bangkok (GMT +07:00)", value: "Asia/Bangkok" },
  { name: "Asia/Singapore (GMT +08:00)", value: "Asia/Singapore" },
  { name: "Asia/Tokyo (GMT +09:00)", value: "Asia/Tokyo" },
  { name: "Pacific/Guam (GMT +10:00)", value: "Pacific/Guam" },
  { name: "Pacific/Noumea (GMT +11:00)", value: "Pacific/Noumea" },
  { name: "Pacific/Majuro (GMT +12:00)", value: "Pacific/Majuro" },
  { name: "Pacific/Enderbury (GMT +13:00)", value: "Pacific/Enderbury" },
];

export const CLIENT_BROWSER_DEFAULT_TIMEZONE =
  Intl.DateTimeFormat().resolvedOptions().timeZone;
export const DEFAULT_TIMEZONE =
  CLIENT_BROWSER_DEFAULT_TIMEZONE || "Africa/Lagos";

export const DAYS = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

export const USER_ROLES = {
  CLIENT: "CLIENT",
  PROVIDER: "PROVIDER",
  ADMIN: "ADMIN",
};

export const ALL_TIMEZONES = [
  { name: "Africa/Lagos (GMT +01:00)", value: "Africa/Lagos" },
  { name: "(Pacific/Honolulu) GMT -10", value: "Pacific/Honolulu" },
  { name: "(America/Anchorage) GMT -9", value: "America/Anchorage" },
  { name: "(America/Los_Angeles) GMT -8", value: "America/Los_Angeles" },
  { name: "(America/Chicago) GMT -6", value: "America/Chicago" },
  { name: "America/New_York(GMT -05:00)", value: "America/New_York" },
  { name: "America/Toronto(GMT -04:00)", value: "America/Toronto" },
  { name: "(Europe/London) GMT +0", value: "Europe/London" },
  { name: "Africa/Accra (GMT +00:00)", value: "Africa/Accra" },
  { name: "Africa/Algiers (GMT +01:00)", value: "Africa/Algiers" },
  { name: "Africa/Harare (GMT +02:00)", value: "Africa/Harare" },
  { name: "Africa/Kigali (GMT +02:00)", value: "Africa/Kigali" },
  { name: "Africa/Nairobi (GMT +03:00)", value: "Africa/Nairobi" },
  { name: "Asia/Dubai (GMT +04:00)", value: "Asia/Dubai" },
  { name: "Asia/Oral (GMT +05:00)", value: "Asia/Oral" },
  { name: "Asia/Almaty (GMT +06:00)", value: "Asia/Almaty" },
  { name: "Asia/Bangkok (GMT +07:00)", value: "Asia/Bangkok" },
  { name: "Asia/Singapore (GMT +08:00)", value: "Asia/Singapore" },
  { name: "Asia/Tokyo (GMT +09:00)", value: "Asia/Tokyo" },
  { name: "Pacific/Guam (GMT +10:00)", value: "Pacific/Guam" },
  { name: "Pacific/Noumea (GMT +11:00)", value: "Pacific/Noumea" },
  { name: "Pacific/Majuro (GMT +12:00)", value: "Pacific/Majuro" },
  { name: "Pacific/Enderbury (GMT +13:00)", value: "Pacific/Enderbury" },
];

export const defaultGlobalConfirmDialogOptions = {
  confirmButtonText: "Confirm",
  cancelButtonText: "Cancel",
  confirmButtonProps: {
    color: "error",
  },
  cancelButtonProps: {
    color: "primary",
    variant: "outlined",
    autoFocus: true,
  },
};

export const PAGINATION_DEFAULT = {
  page: 1,
  limit: 24,
};

export const SESSION_BOOKING_PAGINATION_DEFAULT = {
  page: 1,
  limit: 2,
};

export const THERAPIST_RECOMMENDATION_PAGINATION_DEFAULT = {
  page: 1,
  limit: 3,
};

export const GENDERS = {
  MALE: "MALE",
  FEMALE: "FEMALE",
  // OTHER: "OTHER",
  // PREFER_NOT_TO_SAY: "PREFER_NOT_TO_SAY",
  // NON_BINARY: "NON_BINARY",
};

export const SESSION_TYPE = {
  INDIVIDUAL: "INDIVIDUAL",
  COUPLE: "COUPLE",
  CONSULTATION: "CONSULTATION",
};

export const PROVIDER_TYPE = {
  PSYCHIATRIST: "PSYCHIATRIST",
  PSYCHOLOGIST: "PSYCHOLOGY",
  PSYCHOLOGIST_NAME: "PSYCHOLOGIST",
};

export const NIGERIA = {
  COUNTRY_ID: "150",
  COUNTRY_CODE: "+234",
};

export const languageSelectionOptions: string[] = [
  "English",
  "Yoruba",
  "Igbo",
  "Hausa",
  "Pidgin",
  "Swahili",
  "Kikuyu",
];

export const religionSelectionOptions: string[] = ["Christianity", "Islam"];

export const DEFAULT_PROFILE_PHOTO =
  "https://firebasestorage.googleapis.com/v0/b/akoma-health-v2.appspot.com/o/images%2Fakoma-photo.png?alt=media&token=bcf32d77-9aa3-4a9c-86dc-9fff67afd03c";

export const DOCUMENT_EXTENSION = {
  PDF: "pdf",
  IMG: "img",
  JPG: "jpg",
  PNG: "png",
  JPEG: "jpeg",
  MP3: "mp3",
  MP4: "mp4",
};

export const DOCUMENT_TYPE = {
  IMG: "image/jpeg,image/png,image/jpg",
  PDF: "application/pdf",
  MP3: "audio/mp3,audio/mpeg,audio/x-mpeg,audio/mpg,audio/x-mpg,audio/mpeg3",
  MP4: "video/mp4,video/x-m4v",
};

export const CouponValueType = {
  PERCENTAGE: "PERCENTAGE",
  PRICE_OFF: "PRICE_OFF",
  SESSION_OFF: "SESSION_OFF",
};

export const RATING_VALUES = [
  "Terrible",
  "Not Bad",
  "Average",
  "Good",
  "Excellent",
];

export const REVIEW_TAGS = [
  "Patient",
  "Great Communicator",
  "Active Listener",
  "Professional",
  "Encouraging",
  "Constructive",
  "Honest",
];

export const COUNTRIES = ["Nigeria", "Kenya"];

export const FeatureFlagEnum = {
  CHAT: "chat",
  WHATSAPP: "whatsapp",
  REVIEW: "review",
  SUBSCRIPTION: "subscription",
};

export const NUMBERS = {
  ZERO: 0,
  ONE: 1,
  TEN: 10,
  TWELVE: 12,
  TWENTY: 20,
  THIRTY: 30,
  ONE_HUNDRED: 100,
  THREE_HUNDRED: 300,
  UNLIMITED: 1000,
};
export const S3_BASE_URL = "https://akoma-s3.s3.us-east-2.amazonaws.com";

export const EVENTS = {
  SIGNED_IN: "Signed In",
  SIGNED_OUT: "Signed Out",
  APP_SESSION_STARTED: "App Session Started",
  APP_SESSION_ENDED: "App Session Ended",
};

export const EVENT_ACTIONS = {
  APP_SESSION_LAUNCHED: "App Session Launched",
  APP_SESSION_INACTIVE: "App Session Inactive from timeout",
  APP_SESSION_INACTIVE_LAUNCHED:
    "App Session Inactive from tab closed / stale tab",
  APP_SESSION_ACTIVE: "App Session Active from inactivity",
};

export const SubscriptionIntervalTypeMap = {
  [SubscriptionIntervalType.MONTHLY]: "month",
  [SubscriptionIntervalType.QUATERLY]: "quarter",
  [SubscriptionIntervalType.YEARLY]: "year",
};

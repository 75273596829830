export const routeEnum = {
  LOGIN: "/login",
  CREATE_ACCOUNT: "/create-account",
  RESET_PASSWORD: "/reset-password",
  CHANGE_PASSWORD: "/change-password",
  DASHBOARD: "/dashboard",
  RESCHEDULE_CANCELLATION_POLICY: "/reschedule-cancellation-policy",
  VERIFY_EMAIL_VIA_OTP: "/verify-email-via-otp",
  SUPPORT: "/support",

  // Client
  CLIENTS: "/clients",
  CLIENT_BOOKING: "/bookings",
  CLIENT_PROFILE: "/profile",
  ACTIVATE_ACCOUNT: "/providers/activate-account",
  THERAPIST_DETAIL: "/therapist/:id",
  THERAPIST_PSYCHIATRIST_DETAIL: "/psychiatrist/:id",
  THERAPIST_LIST: "/therapists",
  THERAPIST_PSYCHIATRIST_LIST: "/psychiatrist",
  MESSAGES: "/messages",
  CLIENT_BOOK_THERAPIST: "/therapist/:id/book",
  CLIENT_BOOK_PSYCHIATRIST: "/psychiatrist/:id/book",
  CLIENT_BOOK_THERAPIST_SUCCESS: "/therapist/:id/book/success",
  CLIENT_BOOK_PSYCHIATRIST_SUCCESS: "/psychiatrist/:id/book/success",
  CLIENT_REVIEW_THERAPIST: "/therapist/:id/:meetingId/:clientId/review",
  CLIENT_REVIEW_PSYCHIATRIST: "/psychiatrist/:id/:meetingId/:clientId/review",
  CLIENT_REVIEW_THERAPIST_UPDATE: "/therapist/:id/review",
  CLIENT_REVIEW_PSYCHIATRIST_UPDATE: "/psychiatrist/:id/review",
  CLIENT_PREFERENCE_QUIZ: "/preference-quiz",
  CLIENT_RECOMMENDED_THERAPIST: "/recommended-therapist",
  CLIENT_RECOMMENDED_OTHER_THERAPIST: "/recommended-other-therapist",
  CLIENT_DETAIL: "/clients/:id",
  CLIENT_THERAPIST_REFERRAL: "/clients/:id/therapists/referral",

  // NOTE: Deprecate Review
  CLIENT_REVIEW_SESSION: "/session/:meetingId/:clientId/review",

  // Therapist
  THERAPIST_AVAILABILITY: "/availability",
  PROVIDER_ACCOUNT_COMPLETION: "/providers/account-completion",
  THERAPIST_BOOKING: "/therapist-bookings",

  // partner client
  PARTNER_CLIENT_REDIRECT: "/partner-auth",
  PARTNER_CLIENT_ENROLLEE: "/hmo/book-session",
  PARTNER_CLIENT_ENROLLEE_SUCCESSFUL: "/hmo/:id/book-session/successful",
  PARTNER_CLIENT_AUTHORIZE_REDIRECT: "/hmo/:id/book-session/redirect",

  SUBSCRIPTION_SUCCESSFUL: "/subscription/successful",

  GIFTS: "/gifts",
  GIFTS_SEND: "/gifts/send",
  GIFTS_REDEEM: "/gifts/redeem",
};

import { Navigate, type RouteObject } from "react-router-dom";
import { lazy, type ReactElement } from "react";
import { routeEnum } from "constants/RouteConstants";
import {
  FeatureFlagEnum,
  PROVIDER_TYPE,
  USER_ROLES,
} from "constants/GlobalConstant";
import RootErrorBoundary from "common/RootErrorBoundary";
import AuthGuard from "./guard";
import FeatureFlagGuard from "./FeatureFlagGuard";

const Dashboard = lazy(() => import("features/dashboard/Dashboard"));
const PartnerClientRedirectPage = lazy(
  () => import("features/partnerClient/PartnerClientRedirect")
);
const PartnerClientEnrolleePage = lazy(
  () => import("features/partnerClient/PartnerClientEnrollee")
);
const PartnerClientEnrolleeSuccessfulPage = lazy(
  () => import("features/partnerClient/PartnerClientEnrolleeSuccessful")
);
const PartnerClientEnrolleeRedirectPage = lazy(
  () => import("features/partnerClient/PartnerClientEnrolleeRedirect")
);
const AppProtected = lazy(() => import("AppProtected"));
const EmailVerificationAuthenticated = lazy(
  () =>
    import(
      "features/auth/views/CreateAccount/components/EmailVerification/EmailVerificationAuthenticated"
    )
);
const ActivateProviderAccount = lazy(
  () =>
    import(
      "features/auth/views/ActivateProviderAccount/ActivateProviderAccount"
    )
);
const ClientPreferenceQuizPage = lazy(
  () => import("features/client-preference-quiz/ClientPreferenceQuizPage")
);
const ClientRecommendedTherapistPage = lazy(
  () =>
    import(
      "features/client-recommended-therapist/ClientRecommendedTherapistPage"
    )
);
const ClientRecommendedOtherTherapistPage = lazy(
  () =>
    import(
      "features/client-recommended-therapist/ClientRecommendedOtherTherapistPage"
    )
);
const ClientDashboardTherapistDetail = lazy(
  () =>
    import("features/client-dashboard-therapist/ClientDashboardTherapistDetail")
);
const ClientDashboardBookingsPage = lazy(
  () => import("features/client-dashboard-bookings/ClientDashboardBookingsPage")
);
const ClientDashboardProfilePage = lazy(
  () => import("features/client-dashboaard-profile/ClientDashboardProfilePage")
);
const ClientDashboardTherapistList = lazy(
  () =>
    import("features/client-dashboard-therapist/ClientDashboardTherapistList")
);
const TherapistAvailability = lazy(
  () => import("features/therapist-availability/TherapistAvailability")
);
const ClientBookTherapist = lazy(
  () => import("features/client-book-therapist/ClientBookTherapist")
);
const ClientBookTherapistSuccess = lazy(
  () => import("features/client-book-therapist/ClientBookTherapistSuccessPage")
);
const ProviderDashboardAccountCompletion = lazy(
  () =>
    import(
      "features/provider-dashboard-account-completion/ProviderDashboardAccountCompletion"
    )
);
const ChangePassword = lazy(
  () => import("../features/auth/views/ChangePassword/ChangePassword")
);
const ResetPassword = lazy(
  () => import("../features/auth/views/ResetPassword/ResetPassword")
);
const CreateAccount = lazy(
  () => import("../features/auth/views/CreateAccount/CreateAccount")
);
const Login = lazy(() => import("../features/auth/views/Login/Login"));
const TherapistDashboardBookingsPage = lazy(
  () =>
    import(
      "features/therapist-dashboard-bookings/TherapistDashboardBookingsPage"
    )
);
const ClientsPage = lazy(() => import("features/client/ClientList"));
const ClientDetailPage = lazy(
  () => import("features/client-details/ClientDetails")
);

const ClientTherapistReferralListPage = lazy(
  () => import("features/client/ClientTherapistReferralList")
);

const Message = lazy(() => import("features/message/Message"));

const RescheduleCancellationPolicy = lazy(
  () =>
    import(
      "features/therapist-dashboard-bookings/TherapistDashboardBookingsRescheduleCancellationPolicyPage"
    )
);
const SubscriptionSuccessPage = lazy(
  () => import("features/subscription/SubscriptionSuccessful")
);

// NOTE: Deprecate Review
const ClientReviewSessionPage = lazy(
  () => import("features/client-review/ClientReviewSessionPage")
);

const ClientReviewTherapistPage = lazy(
  () => import("features/client-review/ClientReviewTherapistPage")
);

const GiftSendPage = lazy(() => import("features/gift/GiftSend"));

const GiftRedeemPage = lazy(() => import("features/gift/GiftRedeem"));

type ExtendedRouteObject = RouteObject & {
  guarded?: boolean;
  featureFlag?: string;
};

export const PublicRoutes: ExtendedRouteObject[] = [
  {
    path: routeEnum.LOGIN,
    element: <Login />,
    errorElement: <RootErrorBoundary />,
  },
  {
    path: routeEnum.CREATE_ACCOUNT,
    element: <CreateAccount />,
    errorElement: <RootErrorBoundary />,
  },
  {
    path: routeEnum.RESET_PASSWORD,
    element: <ResetPassword />,
    errorElement: <RootErrorBoundary />,
  },
  {
    path: routeEnum.CHANGE_PASSWORD,
    element: <ChangePassword />,
    errorElement: <RootErrorBoundary />,
  },
  {
    path: routeEnum.ACTIVATE_ACCOUNT,
    element: <ActivateProviderAccount />,
    errorElement: <RootErrorBoundary />,
  },
  {
    path: routeEnum.VERIFY_EMAIL_VIA_OTP,
    element: <EmailVerificationAuthenticated />,
    errorElement: <RootErrorBoundary />,
  },
  {
    path: routeEnum.PARTNER_CLIENT_REDIRECT,
    element: <PartnerClientRedirectPage />,
    errorElement: <RootErrorBoundary />,
  },
  {
    path: routeEnum.PARTNER_CLIENT_ENROLLEE,
    element: <PartnerClientEnrolleePage />,
    errorElement: <RootErrorBoundary />,
  },
  {
    path: routeEnum.PARTNER_CLIENT_ENROLLEE_SUCCESSFUL,
    element: <PartnerClientEnrolleeSuccessfulPage />,
    errorElement: <RootErrorBoundary />,
  },
  {
    path: routeEnum.PARTNER_CLIENT_AUTHORIZE_REDIRECT,
    element: <PartnerClientEnrolleeRedirectPage />,
    errorElement: <RootErrorBoundary />,
  },
  {
    path: routeEnum.CLIENT_REVIEW_SESSION,
    errorElement: <RootErrorBoundary />,
    element: <ClientReviewSessionPage />,
    featureFlag: FeatureFlagEnum.REVIEW,
  },
  {
    path: routeEnum.CLIENT_REVIEW_THERAPIST,
    errorElement: <RootErrorBoundary />,
    element: <ClientReviewTherapistPage />,
    featureFlag: FeatureFlagEnum.REVIEW,
  },
  {
    path: routeEnum.CLIENT_REVIEW_PSYCHIATRIST,
    errorElement: <RootErrorBoundary />,
    element: (
      <ClientReviewTherapistPage
        {...({ providerType: PROVIDER_TYPE.PSYCHIATRIST } as object)}
      />
    ),
    featureFlag: FeatureFlagEnum.REVIEW,
  },
  {
    path: routeEnum.CLIENT_REVIEW_THERAPIST_UPDATE,
    errorElement: <RootErrorBoundary />,
    element: <ClientReviewTherapistPage />,
    featureFlag: FeatureFlagEnum.REVIEW,
  },
  {
    path: routeEnum.CLIENT_REVIEW_PSYCHIATRIST_UPDATE,
    errorElement: <RootErrorBoundary />,
    element: (
      <ClientReviewTherapistPage
        {...({ providerType: PROVIDER_TYPE.PSYCHIATRIST } as object)}
      />
    ),
    featureFlag: FeatureFlagEnum.REVIEW,
  },
  // {
  //   path: routeEnum.CLIENT_REVIEW,
  //   errorElement: <RootErrorBoundary />,
  //   element: <ClientReviewTherapistPage />,
  //   featureFlag: FeatureFlagEnum.REVIEW,
  // },
  {
    path: routeEnum.GIFTS,
    errorElement: <RootErrorBoundary />,
    element: <GiftSendPage />,
  },
  {
    path: routeEnum.GIFTS_SEND,
    errorElement: <RootErrorBoundary />,
    element: <GiftSendPage />,
  },
];

export const ProtectedRoutes: ExtendedRouteObject[] = [
  {
    path: "/",
    hasErrorBoundary: true,
    element: <AppProtected />,
    errorElement: <RootErrorBoundary />,
    guarded: true,
    children: [
      {
        path: routeEnum.DASHBOARD,
        element: <Dashboard />,
        errorElement: <RootErrorBoundary />,
        roles: [USER_ROLES.CLIENT, USER_ROLES.PROVIDER],
      },

      {
        path: routeEnum.THERAPIST_DETAIL,
        element: <ClientDashboardTherapistDetail />,
      },
      {
        path: routeEnum.THERAPIST_PSYCHIATRIST_DETAIL,
        element: (
          <ClientDashboardTherapistDetail
            providerType={PROVIDER_TYPE.PSYCHIATRIST}
          />
        ),
      },
      {
        path: routeEnum.CLIENT_PROFILE,
        errorElement: <RootErrorBoundary />,
        element: <ClientDashboardProfilePage />,
      },
      {
        path: routeEnum.CLIENT_BOOKING,
        errorElement: <RootErrorBoundary />,
        element: <ClientDashboardBookingsPage />,
      },
      {
        path: routeEnum.CLIENT_THERAPIST_REFERRAL,
        errorElement: <RootErrorBoundary />,
        element: <ClientTherapistReferralListPage />,
      },
      {
        path: routeEnum.THERAPIST_LIST,
        errorElement: <RootErrorBoundary />,
        element: <ClientDashboardTherapistList />,
      },
      {
        path: routeEnum.THERAPIST_PSYCHIATRIST_LIST,
        errorElement: <RootErrorBoundary />,
        element: (
          <ClientDashboardTherapistList
            {...({ providerType: PROVIDER_TYPE.PSYCHIATRIST } as object)}
          />
        ),
      },
      {
        path: routeEnum.THERAPIST_AVAILABILITY,
        errorElement: <RootErrorBoundary />,
        element: <TherapistAvailability />,
      },
      {
        path: routeEnum.THERAPIST_BOOKING,
        errorElement: <RootErrorBoundary />,
        element: <TherapistDashboardBookingsPage />,
      },
      {
        path: routeEnum.PROVIDER_ACCOUNT_COMPLETION,
        errorElement: <RootErrorBoundary />,
        element: <ProviderDashboardAccountCompletion />,
      },
      {
        path: routeEnum.CLIENT_PREFERENCE_QUIZ,
        errorElement: <RootErrorBoundary />,
        element: <ClientPreferenceQuizPage />,
      },
      {
        path: routeEnum.CLIENT_RECOMMENDED_THERAPIST,
        errorElement: <RootErrorBoundary />,
        element: <ClientRecommendedTherapistPage />,
      },
      {
        path: routeEnum.MESSAGES,
        errorElement: <RootErrorBoundary />,
        element: <Message />,
        featureFlag: FeatureFlagEnum.CHAT,
      },
      {
        path: routeEnum.CLIENT_RECOMMENDED_OTHER_THERAPIST,
        errorElement: <RootErrorBoundary />,
        element: <ClientRecommendedOtherTherapistPage />,
      },
      {
        path: routeEnum.CLIENTS,
        errorElement: <RootErrorBoundary />,
        element: <ClientsPage />,
      },
      {
        path: routeEnum.CLIENT_DETAIL,
        errorElement: <RootErrorBoundary />,
        element: <ClientDetailPage />,
      },
      {
        path: routeEnum.GIFTS,
        errorElement: <RootErrorBoundary />,
        element: <GiftSendPage />,
      },
      {
        path: routeEnum.GIFTS_SEND,
        errorElement: <RootErrorBoundary />,
        element: <GiftSendPage />,
      },
      // {
      //   path: routeEnum.GIFTS_REDEEM,
      //   errorElement: <RootErrorBoundary />,
      //   element: <GiftRedeemPage />,
      // },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ] as any,
  },
  {
    path: routeEnum.CLIENT_BOOK_THERAPIST,
    errorElement: <RootErrorBoundary />,
    element: <ClientBookTherapist />,
  },
  {
    path: routeEnum.CLIENT_BOOK_PSYCHIATRIST,
    errorElement: <RootErrorBoundary />,
    element: (
      <ClientBookTherapist
        {...({ providerType: PROVIDER_TYPE.PSYCHIATRIST } as object)}
      />
    ),
  },
  {
    path: routeEnum.CLIENT_BOOK_THERAPIST_SUCCESS,
    errorElement: <RootErrorBoundary />,
    element: <ClientBookTherapistSuccess />,
  },
  {
    path: routeEnum.CLIENT_BOOK_PSYCHIATRIST_SUCCESS,
    errorElement: <RootErrorBoundary />,
    element: (
      <ClientBookTherapistSuccess
        {...({ providerType: PROVIDER_TYPE.PSYCHIATRIST } as object)}
      />
    ),
  },
  {
    path: routeEnum.SUBSCRIPTION_SUCCESSFUL,
    errorElement: <RootErrorBoundary />,
    element: <SubscriptionSuccessPage />,
    featureFlag: FeatureFlagEnum.SUBSCRIPTION,
  },
  {
    path: routeEnum.RESCHEDULE_CANCELLATION_POLICY,
    element: <RescheduleCancellationPolicy />,
    errorElement: <RootErrorBoundary />,
  },
];

const allRoutes: ExtendedRouteObject[] = [
  ...PublicRoutes,
  ...ProtectedRoutes,
  {
    path: "*",
    element: <Navigate to={routeEnum.DASHBOARD} replace />,
  },
];

function featureFlagGuard(route: any) {
  if (route?.featureFlag) {
    // eslint-disable-next-line no-param-reassign
    route.element = (
      <FeatureFlagGuard
        featureFlag={route.featureFlag}
        element={route.element}
      />
    );
  }

  if (route.children?.length) {
    // eslint-disable-next-line no-param-reassign
    route.children = route.children.map(featureFlagGuard);
  }

  return route;
}

const appRoutes = allRoutes.map((route) => {
  if (route?.guarded && route?.element) {
    // eslint-disable-next-line no-param-reassign
    route.element = (
      <AuthGuard route={route} component={route.element as ReactElement} />
    );
  }

  return featureFlagGuard(route);
});

export default appRoutes;

/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  ThemeOptions,
  alpha,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";

export const defaultTheme = customizeTheme();

export const lightTheme = responsiveFontSizes(
  customizeTheme({
    palette: {
      primary: {
        lighter: "#F9FBFF",
        light: "#EBF8FF",
        main: "#0356B2",
        dark: "#001C50",
        contrastText: "#FFFFFF",
      },
      secondary: {
        lighter: "#E4E7F1",
        light: "#B3C1DB",
        main: "#002F86",
        dark: "#00215E",
        contrastText: "#FFFFFF",
      },
      success: {
        ...defaultTheme.palette.success,
        lighter: "#F3FEF7",
        light: "#DFFFE9",
        main: "#038A30",
        dark: "#024518",
      },
      error: {
        ...defaultTheme.palette.error,
        lighter: alpha(defaultTheme.palette.error.main, 0.2),
      },
      divider: "#E4E7F1",
      warning: {
        ...defaultTheme.palette.warning,
      },
    },
    shadows: [
      "none",
      "0px 2px 3px 0px  rgba(139, 152, 165, 0.075),0px 4px 5px 0px  rgba(139, 152, 165, 0.075),0px 2px 1px -1px  rgba(139, 152, 165, 0.075)",
      "0px 2px 5px 0px rgba(145, 158, 171, 0.12),0px 2px 2px 0px rgba(145, 158, 171, 0.12),0px 3px 1px -2px rgba(145, 158, 171, 0.12)",
      "0px 2px 9px 0px rgba(145, 158, 171, 0.12),0px 1px 3px 0px rgba(145, 158, 171, 0.12),0px 3px 3px -2px rgba(145, 158, 171, 0.12)",
      "0px 4px 4px -1px rgba(145, 158, 171, 0.12),0px 0px 5px 0px rgba(145, 158, 171, 0.12),0px 1px 10px 0px rgba(145, 158, 171, 0.12)",
      "0px 6px 6px -1px rgba(145, 158, 171, 0.12),0px -1px 10px 0px rgba(145, 158, 171, 0.12),0px 1px 14px 0px rgba(145, 158, 171, 0.12)",
      "0px 6px 6px -1px rgba(145, 158, 171, 0.2),0px -2px 12px 0px rgba(145, 158, 171, 0.2),0px 1px 18px 0px rgba(145, 158, 171, 0.2)",
      ...defaultTheme.shadows
        .slice(6)
        .map(
          () =>
            "0px 6px 6px -1px rgba(145, 158, 171, 0.2),0px -2px 12px 0px rgba(145, 158, 171, 0.2),0px 1px 18px 0px rgba(145, 158, 171, 0.2)"
        ),
    ] as any,
  })
);
export const darkTheme = responsiveFontSizes(
  customizeTheme({ palette: { mode: "dark" } })
);

export function customizeTheme(theme?: ThemeOptions) {
  return createTheme({
    ...theme,
    breakpoints: {
      values: {
        xs: 0,
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280,
        "2xl": 1536,
      },
    },
    shape: {
      borderRadius: 8,
    },
    typography: {
      fontFamily: [
        "'Public Sans'",
        "sans-serif",
        "-apple-system",
        "BlinkMacSystemFont",
      ].join(),
      // fontSize: 12,
      // fontWeightRegular: 500,
      // fontWeightBold: 600,
      small: { fontSize: 12, fontWeight: 500 },
      button: {
        textTransform: "none",
      },
    },
    components: {
      MuiIcon: {
        defaultProps: {
          baseClassName: "material-symbols-outlined",
        },
      },
      MuiIconButton: {
        defaultProps: {},
        styleOverrides: {
          root: ({ theme, ownerState }) => {
            return {
              ...(ownerState.shape === "square"
                ? {
                    borderRadius: 10,
                  }
                : {}),
              ...(ownerState.variant === "outlined"
                ? {
                    color:
                      theme.palette[String(ownerState.color)]?.main ||
                      theme.palette.grey[500],
                    borderColor:
                      theme.palette[String(ownerState.color)]?.main ||
                      theme.palette.grey[500],
                    borderStyle: "solid",
                    borderWidth: 1,
                    backgroundColor: "transparent",
                    "&:hover": {
                      backgroundColor: alpha(
                        theme.palette[String(ownerState.color)]?.main ||
                          theme.palette.grey[500],
                        0.1
                      ),
                    },
                  }
                : {}),
              ...(ownerState.variant === "filled"
                ? {
                    color:
                      theme.palette[String(ownerState.color)]?.dark ||
                      theme.palette.grey[500],

                    backgroundColor: alpha(
                      theme.palette[String(ownerState.color)]?.main ||
                        theme.palette.grey[500],
                      0.1
                    ),
                    "&:hover": {
                      backgroundColor: alpha(
                        theme.palette[String(ownerState.color)]?.main ||
                          theme.palette.grey[500],
                        0.4
                      ),
                    },
                  }
                : {}),
            };
          },
        },
      },
      MuiTabs: {
        defaultProps: {
          variant: "scrollable",
          scrollButtons: "auto",
          allowScrollButtonsMobile: true,
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            minWidth: 150,
            fontSize: "1rem",
          },
        },
      },
      MuiLink: {
        defaultProps: {
          variant: "body1",
        },
        styleOverrides: {
          root: ({ theme }) => ({ fontWeight: "bold" }),
        },
      },
      MuiButton: {
        defaultProps: {
          disableElevation: true,
          variant: "contained",
        },
        variants: [
          {
            props: { variant: "containedSecondary" },
            style: {
              borderRadius: 8,
              background: "#E4F1FF",
              color: "#038A30",
              ":hover": {
                background: "#E4F1FF",
                color: "#038A30",
              },
              ":focus": {
                background: "#E4F1FF",
                color: "#038A30",
              },
              ":active": {
                background: "#E4F1FF",
                color: "#038A30",
              },
            },
          },
          {
            props: { variant: "containedSuccess" },
            style: {
              borderRadius: 8,
              border: "1px solid #20BF55",
              background: "#EEFEF6",
              color: "#038A30",
              ":hover": {
                background: "#EEFEF6",
                color: "#038A30",
              },
              ":focus": {
                background: "#EEFEF6",
                color: "#038A30",
              },
              // ":active": {
              //   background: "#EEFEF6",
              //   color: "#038A30",
              // },
            },
          },
          {
            props: { variant: "containedInfo" },
            style: {
              borderRadius: 8,
              border: "1px solid #EBF8FF",
              // border: "1px solid #0288d1",
              background: "#EBF8FF",
              color: "#004898",
              ":hover": {
                background: "#EBF8FF",
                color: "#004898",
              },
              ":focus": {
                background: "#EBF8FF",
                color: "#004898",
              },
              // ":active": {
              //   background: "#EBF8FF",
              //   color: "#004898",
              // },
            },
          },
          {
            props: { variant: "containedGrey" },
            style: {
              borderRadius: 8,
              background: "#fff",
              color: "#6C6C6C",
              ":hover": {
                background: "#fff",
                color: "#6C6C6C",
              },
              ":focus": {
                background: "#fff",
                color: "#6C6C6C",
              },
              ":active": {
                background: "#fff",
                color: "#6C6C6C",
              },
            },
          },
          {
            props: { variant: "linkSecondary" },
            style: {
              borderRadius: "20px",
              background: "#F3F8FF",
              color: "#0065FF",
              textDecoration: "underline",
              ":hover": {
                opacity: 0.8,
                background: "#F3F8FF",
                textDecoration: "underline",
              },
              ":focus": {
                background: "#F3F8FF",
                opacity: 0.8,
                textDecoration: "underline",
              },
              ":active": {
                background: "#F3F8FF",
                opacity: 0.8,
                textDecoration: "underline",
              },
            },
          },
          {
            props: { size: "large" },
            style: () => ({ padding: "14px 22px" }),
          },
          {
            props: { size: "medium" },
            style: () => ({ padding: "10px 16px" }),
          },
        ],
      },
      MuiInput: {
        defaultProps: { disableUnderline: true },
      },
      MuiInputLabel: {
        defaultProps: {
          shrink: true,
          classes: { asterisk: "text-error-main" },
        },
      },
      MuiCard: {
        styleOverrides: { root: { borderRadius: 16 } },
      },
      MuiOutlinedInput: {
        defaultProps: { notched: false },
      },
      MuiTextField: {
        defaultProps: {
          variant: "outlined",
          // InputProps: { disableUnderline: true },
          // InputLabelProps: { shrink: true },
        },
        variants: [
          // {
          //   props: { plain: true },
          //   style: ({ theme }) => {
          //     return {
          //       "& .MuiInputBase-root > fieldset": {
          //         border: "none",
          //       },
          //     };
          //   },
          // },
          {
            props: { size: "small" },
            style: ({ theme }) => {
              return {
                // "& .MuiInputBase-root > fieldset": {
                //   border: "none",
                // },
              };
            },
          },
        ],
        styleOverrides: {
          root: ({ theme, ownerState }) => {
            if (ownerState.variant !== "outlined") return {};
            const color = ownerState.error
              ? theme.palette.error.main
              : theme.palette.primary.main;
            return {
              "& label": {
                ...theme.typography.body1,
                fontSize: "1.125rem",
                color: theme.palette.text.primary,
                top: 8,
                left: -12,
                "& + .MuiInputBase-root": {
                  marginTop: theme.spacing(3),
                },
              },
              "& .MuiInputBase-root": {
                backgroundColor:
                  theme.palette.mode === "light" ? "#FCFCFC" : "#1A2027",
                "&:focus-within": {
                  backgroundColor:
                    theme.palette.mode === "light"
                      ? theme.palette.primary.lighter
                      : "#1A2027",
                },

                "& + .MuiFormHelperText-root": {
                  marginLeft: 0,
                },

                // // padding: ownerState.size === "small" ? "0px 12px" : "0px 24px",
                // ...(ownerState?.InputProps?.startAdornment
                //   ? {
                //       paddingLeft: ownerState.size === "small" ? "7px" : "14px",
                //     }
                //   : {}),
                // ...(ownerState?.InputProps?.endAdornment
                //   ? {
                //       paddingRight:
                //         ownerState.size === "small" ? "7px" : "14px",
                //     }
                //   : {}),
                // overflow: "hidden",
                // borderRadius: 8,
                // position: "relative",
                // backgroundColor:
                //   theme.palette.mode === "light" ? "#FCFCFC" : "#1A2027",
                // border: "1px solid",
                // borderColor: ownerState.error
                //   ? color
                //   : theme.palette.mode === "light"
                //   ? "#E0E3E7"
                //   : "#2D3843",
                // // transition: theme.transitions.create([
                // //   "border-color",
                // //   "background-color",
                // //   "box-shadow",
                // // ]),
                // "&:focus-within": {
                //   //   boxShadow: `${color} 0 0 0 0.05rem`,
                //   borderColor: color,
                //   backgroundColor:
                //     theme.palette.mode === "light"
                //       ? theme.palette.primary.lighter
                //       : "#1A2027",
                // },
                // // "label + &": {
                // //   // fontWeight: 500,
                // //   // marginTop: theme.spacing(4),
                // //   marginTop: "25px",
                // // },
                // "& .MuiInputBase-input": {
                //   padding:
                //     ownerState.size === "small" ? "8.5px 7px" : "16.5px 14px",
                //   ...(ownerState?.InputProps?.startAdornment
                //     ? { paddingLeft: 0 }
                //     : {}),
                //   ...(ownerState?.InputProps?.endAdornment
                //     ? { paddingRight: 0 }
                //     : {}),
                //   // ...theme.typography.body1,
                // },
              },
            };
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          // input: {
          //   textTransform: "capitalize",
          // },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            width: "100%",
            margin: 10,
          },
        },
        defaultProps: {
          maxWidth: "xs",
        },
      },
      MuiPaper: {
        defaultProps: {},
        variants: [],
      },
      MuiChip: {
        variants: [
          {
            props: { size: "medium" },
            style: () => ({
              height: 40,
              borderRadius: 32,
              textTransform: "capitalize",
              "& .MuiChip-icon": {
                marginLeft: 12,
                marginRight: -6,
              },
            }),
          },
        ],
      },
    },
  });
}

// NonForwardedProps.set('Button', ['rounded']);

/* eslint-disable react/no-unstable-nested-components */
import "./App.css";
import { Suspense } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { init } from "@amplitude/analytics-node";
import { Container } from "@mui/material";
import { SnackbarProvider } from "notistack";

import { SnackbarCloseButton } from "common/SnackbarCloseButton";
import ConfirmDialogProvider from "common/ConfirmDialog/ConfirmDialogProvider";
import AppThemeProvider from "AppThemeProvider";
import { FeatureFlagApi } from "api/FeatureFlagApi";
import { PostHogProvider } from "posthog-js/react";
import config from "configs/config";
import ErrorContent from "common/ErrorContent";
import LoadingContent from "common/LoadingContent";
import AppLoader from "common/AppLoader";
import AppRouter from "./router";
import SentryErrorBoundary from "./common/SentryErrorBoundary";

init("3551314ca1db7993284a242dd9143823");

function App() {
  const featureFlagsQueryResult =
    FeatureFlagApi.useGetFeatureFlagsQuery(undefined);

  return (
    <LoadingContent
      loading={featureFlagsQueryResult.isLoading}
      error={featureFlagsQueryResult.isError}
      loadingContent={<AppLoader />}
      errorContent={
        <Container maxWidth="md" className="mt-10">
          <ErrorContent onReload={featureFlagsQueryResult.refetch} />
        </Container>
      }
      onReload={featureFlagsQueryResult.refetch}
    >
      <PostHogProvider
        apiKey={config.PUBLIC_POSTHOG_KEY}
        options={{
          api_host: config.PUBLIC_POSTHOG_HOST,
          bootstrap: {
            distinctID: "app",
            isIdentifiedID: true,
            featureFlags: featureFlagsQueryResult.data?.data,
          },
        }}
      >
        <AppThemeProvider>
          <ConfirmDialogProvider>
            <CssBaseline />

            <SentryErrorBoundary>
              <SnackbarProvider
                classes={{ containerRoot: "z-alert" }}
                action={(snackbarKey) => (
                  <SnackbarCloseButton snackbarKey={snackbarKey} />
                )}
                preventDuplicate
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <Suspense fallback={<AppLoader />}>
                  <AppRouter />
                </Suspense>
              </SnackbarProvider>
            </SentryErrorBoundary>
          </ConfirmDialogProvider>
        </AppThemeProvider>
      </PostHogProvider>
    </LoadingContent>
  );
}

export default App;
